@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

*:focus {
    outline-color: #3bb6f4;
}

button {
    transition: 0.3s;
}

button:hover,
button:focus {
    opacity: .8;
}

.filter404 {
    filter: invert(40%) sepia(27%) saturate(2476%) hue-rotate(156deg) brightness(118%) contrast(120%);
    width: 5rem;
}

.container404 label{
    color: #00b6ef;
    font-size: 5rem;
}
@media (min-width: 425px){
    .filter404 {
        width: 10rem;
    }
    
    .container404 label{
        font-size: 10rem;
    }
}